.gmvdiv {
  margin-top: 60px;
  margin-bottom: 20px;

  .gmvamount {
    span {
      color: $black3;
      font-size: 24px;
      font-family: $font-bold;
      letter-spacing: -1.5px;
    }
  }
}

.loadFundsText {
  text-decoration: underline;
  margin-left: auto;
  font-size: 16px;
  cursor: pointer;
  color: $primary;
}

.ant-select-arrow {
  color: #000000;
}

.centivPage {
  display: flex;
  flex-direction: column;
  gap: 25px;

  .cards-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;

    .three-cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      border-radius: 7px !important;
      // margin-top: 10px;
      justify-content: space-between;
      overflow: hidden;
      flex-basis: 70%;

      .card {
        border-radius: 0 !important;
        background-color: $primary;
        padding: 15px 25px;
        display: flex;
        flex-direction: column;
        flex: 1;
        // min-height: 300px;
        justify-content: end;
        min-width: 260px;

        &:first-child {
          flex: 1;
          // border-top-left-radius: 7px !important;
          // border-bottom-left-radius: 7px !important;
        }

        // .cent {
        //   min-width: 260px;
        // }

        &:nth-child(odd) {
          background-color: $light-purple !important;
        }

        &:nth-child(even) {
          background-color: $lightest-purple !important;
        }

        &:last-child {
          // border-top-right-radius: 7px !important;
          // border-bottom-right-radius: 7px !important;
        }

        // span {
        //   color: $white;
        //   font-family: $font-regular;
        //   font-size: 16px;
        //   letter-spacing: -0.5px;
        // }

        h4 {
          margin: 0;
          font-size: 20px;
          font-family: $font-space-regular;
          color: $black3;
          margin-left: 15px;
        }

        span {
          color: $black3;
          font-size: 14px;
          line-height: 23px;
          font-family: $font-space-regular;
          letter-spacing: -0.5px;
          margin-bottom: 5px;
        }

        p {
          margin: 0;
          font-size: 12px;
          color: $white;
          font-family: $font-regular;
        }

        .card-circles {
          width: 100px;
          height: 150px;
          opacity: 0.2;
          position: absolute;
          top: -23px;
          right: -13px;
        }

        .balance {
          display: flex;
          flex-direction: row;
          align-items: center;

          h4 {
            letter-spacing: -0.68px;
          }
        }

        img {
          width: 30px;
          height: 30px;
          object-fit: contain;
        }

        .arrow {
          height: 15px;
          width: 15px;
          margin-left: 15px;
        }

        .card-logo {
          width: 150px;
          height: 200px;
          opacity: 0.1;
          right: 45px;
        }

        &.overview {
          width: 50%;
          background: linear-gradient(180deg, #3a3ab9 0%, #000080 100%);
          flex-direction: row;
          align-items: center;
        }

        .card-content {
          flex: 1;
        }
      }
    }

    .centivCards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      border-radius: 7px !important;
      // margin-top: 10px;
      justify-content: space-between;
      overflow: hidden;
      flex-basis: 100%;

      .card {
        border-radius: 0 !important;
        background-color: $primary;
        padding: 25px 25px;
        display: flex;
        flex-direction: column;
        flex: 1;
        // min-height: 300px;
        justify-content: end;
        min-width: 200px;

        &:first-child {
          flex: 1;
          // border-top-left-radius: 7px !important;
          // border-bottom-left-radius: 7px !important;
        }

        &:nth-child(odd) {
          background-color: $light-purple !important;
        }

        &:nth-child(even) {
          background-color: $lightest-purple !important;
        }

        h4 {
          margin: 0;
          font-size: 20px;
          font-family: $font-space-regular;
          color: $black3;
          margin-left: 15px;
        }

        span {
          color: $black3;
          font-size: 14px;
          line-height: 23px;
          font-family: $font-space-regular;
          letter-spacing: -0.5px;
          margin-bottom: 5px;
        }

        p {
          margin: 0;
          font-size: 12px;
          color: $white;
          font-family: $font-regular;
        }

        .card-circles {
          width: 100px;
          height: 150px;
          opacity: 0.2;
          position: absolute;
          top: -23px;
          right: -13px;
        }

        .balance {
          display: flex;
          flex-direction: row;
          align-items: center;

          h4 {
            font-size: 28px;
            letter-spacing: -0.68px;
          }
        }

        img {
          width: 30px;
          height: 30px;
          object-fit: contain;
        }

        .arrow {
          height: 15px;
          width: 15px;
          margin-left: 15px;
        }

        .card-logo {
          width: 150px;
          height: 200px;
          opacity: 0.1;
          right: 45px;
        }

        &.overview {
          width: 50%;
          background: linear-gradient(180deg, #3a3ab9 0%, #000080 100%);
          flex-direction: row;
          align-items: center;
        }

        .card-content {
          flex: 1;
        }
      }
    }

    .card-top-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
      gap: 20px;

      hr {
        flex: 2;
      }

      h3 {
        flex: 1;
        font-size: 18px;
        font-family: $font-regular;
        color: $light-black;
        justify-content: center;
        display: flex;
        margin-bottom: 0;
      }

      .dateFilter {
        display: flex;
        flex: 1;
        align-items: flex-end;
        // margin-top: 20px;
        align-self: end;
        justify-self: flex-end;

        .c-field-container {
          margin: 0 !important;
        }
      }
    }
  }

  .dashboard-cardsEmployer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 7px !important;
    overflow: hidden;
    // margin: 0 -10px 45px;

    .cardEmloyer {
      min-width: 170px;
      border-radius: 0 !important;
      background-color: $primary;
      padding: 15px 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      // overflow: hidden;
      flex: 1;
      min-height: 230px;
      justify-content: space-between;
      align-items: flex-start;

      &:nth-child(2),
      &:nth-child(3) {
        flex: 1.2;
      }

      &:nth-child(odd) {
        background-color: $light-purple !important;
      }

      &:nth-child(even) {
        background-color: $lightest-purple !important;
      }

      &:last-child {
      }

      .spent {
        color: $black3;
        font-size: 13px;
        line-height: 23px;
        font-family: $font-space-semiBold;
        letter-spacing: -0.5px;
      }

      h4 {
        margin: 0;
        font-size: 16px;
        font-family: $font-space-medium;
        color: $black3;
      }

      span {
        color: $black3;
        font-size: 12px;
        line-height: 19px;
        font-family: $font-space-regular;
        letter-spacing: 0;
      }

      p {
        margin: 0;
        font-size: 12px;
        color: $white;
        font-family: $font-regular;
      }

      .card-circles {
        width: 100px;
        height: 150px;
        opacity: 0.2;
        position: absolute;
        top: -23px;
        right: -13px;
      }

      img {
        width: 35px;
        height: 35px;
        object-fit: contain;
      }

      .card-logo {
        width: 150px;
        height: 200px;
        opacity: 0.1;
        right: 45px;
      }

      &.overview {
        width: 50%;
        background: linear-gradient(180deg, #3a3ab9 0%, #000080 100%);
        flex-direction: row;
        align-items: center;
      }

      .card-content {
        flex: 1;
      }

      // &.centiv {
      //   background: linear-gradient(180deg, #313131 0%, #000000 89.54%);
      //   justify-content: flex-end;
      // }
      // &.payd {
      //   background-color: $primary;
      //   justify-content: flex-end;
      // }
    }
  }
}

.chart-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 25px;

  .ant-picker-range {
    margin-bottom: 20px;
    display: flex;
    align-self: flex-end;
    background: $picker;
  }
}

.chart-inline {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 50%;
  gap: 20px;

  .chart {
    width: calc(50% - 20px);
  }
}

.chart-body {
  padding: 0px 10px 10px 10px;
  min-height: 300px;
  height: 400px;
  width: 100%;
  overflow: hidden;

  // > div {
  //   min-height: 300px;
  // }

  &.empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.chart {
  border: 1px solid $border-light;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  width: 100%;
  min-height: 200px;

  .chart-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 30px 0;
    gap: 25px;

    // .rangePicker {
    //   margin: 0;

    //   .c-field {
    //     .ant-picker {
    //       background-color: $white !important;

    //       input {
    //         background-color: $white !important;
    //       }

    //       svg {
    //         color: $primary;
    //       }
    //     }
    //   }
    // }

    h1 {
      font-family: $font-semiBold;
      color: $black3;
      letter-spacing: 0px;
      font-size: 20px;
    }

    .c-field-container {
      min-width: 220px;
    }

    h5 {
      color: $black;
      margin-bottom: 0;
      font-family: $font-regular;
    }
  }

  .chart-head-pie {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 30px 0;

    h1 {
      font-family: $font-regular;
      color: $black3;
      letter-spacing: -1px;
    }

    h5 {
      color: $black;
      margin-bottom: 0;
      font-family: $font-regular;
    }
  }

  .chart-dropDown {
    min-width: 10% !important;
    align-self: flex-end;
    justify-self: flex-start;
    margin-bottom: 25px;
  }
}

.c-centiv-list {
  .three-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 7px 7px 0 0 !important;
    justify-content: space-between;
    overflow: hidden;
    flex-basis: 100% !important;
  }

  .card {
    border-radius: 0 !important;
    background-color: $primary;
    padding: 0px 15px;
    display: flex;
    flex-direction: row;
    flex: 1;
    min-height: 55px;
    max-height: 55px;
    justify-content: space-between;
    align-items: center;
    min-width: 260px;

    &:first-child {
      min-width: 365px;
    }

    &:nth-child(odd) {
      background-color: $light-purple !important;
    }

    &:nth-child(even) {
      background-color: $lightest-purple !important;
    }

    h4 {
      margin: 0;
      font-size: 18px;
      font-family: $font-space-regular;
      font-weight: 400;
      line-height: 33px;
      color: $black3;
    }

    span {
      color: $black3;
      font-size: 14px;
      font-family: $font-space-regular;
      line-height: 23px;
      letter-spacing: -0.2px;
      color: $black3;
      font-style: normal;
      margin-bottom: 0;
    }

    .card-content {
      flex: 1;
    }
  }
}

.list-container {
  // height: 800px;
  background-color: $white;
  border: 1px solid #bbc5d84d;
  border-radius: 0px 0px 7px 7px;
  display: flex;
  transition: all 0.5s;
}

.list-container2 {
  // height: 800px;

  // border: 1px solid #bbc5d84d;
  // border-radius: 0px 0px 7px 7px;
  display: flex;
  // transition: all 0.5s;
}

.left-side {
  max-height: 530px;
  min-height: 400px;
  display: flex;
  padding: 16px;
  flex: 3;
  flex-direction: column;

  .title {
    color: $black3;
    font-style: normal;
    font-family: $font-nexa-regular;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.2px;
    margin-bottom: 10px;
  }

  .list {
    @include scroll-y;
    padding: 10px 0;

    .list-card {
      display: flex;
      padding: 10px;
      background: #f4f7f9;
      border-radius: 7px;
      margin: 10px 0;
      display: flex;
      flex-direction: column;
      margin-right: 10px;
      transition: all 0.5s;
      cursor: pointer;

      .title {
        font-family: $font-nexa-regular;
        font-size: 12px;
        line-height: 24px;
        color: #2f5484;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        margin-bottom: 0;

        span {
          font-size: 10px;
          line-height: 16px;
          color: $green-light;
        }

        .inactive {
          color: $redish;
        }
      }

      .progress {
        &.complete {
          .ant-progress-inner {
            .ant-progress-bg {
              background-color: $green-light;
            }
          }
        }

        .ant-progress-inner {
          background-color: $dark-gray;
          height: 5px;

          .ant-progress-bg {
            height: 5px !important;
          }
        }
      }

      .balance-detail {
        display: flex;
        align-items: flex-end;
        gap: 5px;

        h6,
        .amount,
        .remaining-amount,
        .slash,
        .currency {
          font-size: 12px;
          line-height: 20px;
          letter-spacing: -0.2px;
          font-family: $font-nexa-regular;
          margin-bottom: 0;
        }

        h6 {
          color: #697ea4;
        }

        .amount {
          color: $primary;
        }

        .slash {
          color: $dark-gray;
        }

        .remaining-amount {
          color: $black3;
        }

        .currency {
          color: $black3;
          font-size: 8px;
        }
      }

      &.selected {
        background: $primary;

        .title {
          color: $white;

          span {
            color: $white;
          }
        }

        .progress {
          .ant-progress-inner {
            background-color: rgba($white, $alpha: 0.2);
            height: 5px;

            .ant-progress-bg {
              height: 4px !important;
              background-color: $white;
            }
          }
        }

        .balance-detail {
          h6,
          .amount,
          .remaining-amount,
          .slash,
          .currency {
            color: $white;
          }
        }
      }
    }
  }
}

.right-side {
  display: flex;
  flex: 7;
  background: linear-gradient(180deg, #fefeff 0%, #f3f5f9 100%);
  border-left: 1px solid rgba(187, 197, 216, 0.3);
  border-radius: 7px 0px 7px 7px;
  transition: all 0.5s;

  .unselected {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    flex-direction: column;
    gap: 12px;
    transition: all 0.5s;

    .cardBlack {
      display: flex;
      flex-direction: column;
      background-color: $white;
      border-radius: 10px;
      padding: 20px;

      .title {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        gap: 10px;

        svg {
          width: 50px;
          height: auto;
        }

        p {
          font-size: 28px;
          line-height: 24px;
          color: $black3;
        }

        span {
          font-size: 18px;
          line-height: 16px;
          color: $black3;
          margin-bottom: 0;
        }
      }

      p {
        font-size: 18px;
        line-height: 28px;
        color: $black3;
        margin-bottom: 0;

        span {
          color: $primary;
        }
      }
    }

    .title {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 29px;
      color: $black3;
    }
  }

  .selected {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    // flex: 1;
    gap: 12px;
    padding: 20px;
    transition: all 0.5s;
    width: 100%;

    .left-side-section {
      display: flex;
      flex-direction: column;
      flex: 6;
      flex-basis: 60%;

      .title {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: #2f5484;
        font-family: $font-nexa-regular;

        span {
          font-size: 18px;
          line-height: 34px;
          letter-spacing: -1px;
          color: #2d4164;
          font-family: $font-nexa-regular;
        }
      }

      .big-card {
        // width: 300;
        // height: auto;
        position: relative;

        display: flex;

        .details {
          position: absolute;
          left: 25px;

          div {
            color: $white;
            position: absolute;
            width: max-content;

            &:first-child {
              display: flex;
              flex: 1;
              top: 100px;
              font-size: 12px;
              font-family: $font-nexa-regular;
              line-height: 15px;
            }

            &:nth-child(2) {
              position: absolute;
              top: 120px;
              font-family: $font-nexa-regular;
              font-weight: 400;
              font-size: 15px;
              line-height: 16px;
              letter-spacing: 1.2px;
            }

            &:nth-child(3) {
              top: 165px;
              font-family: $font-nexa-regular;
              font-weight: 200;
              font-size: 12px;
              font-size: 13.2139px;
              line-height: 16px;
            }

            &:last-child {
              top: 180px;
              font-family: $font-nexa-regular;
              font-weight: 400;
              font-size: 18px;
              line-height: 29px;
              letter-spacing: -0.94385px;
            }
          }
        }

        img {
        }
      }

      .big-card2 {
        // width: 300;
        // height: auto;
        position: relative;

        display: flex;

        .details {
          position: absolute;
          left: 25px;

          div {
            color: $white;
            position: absolute;
            width: max-content;

            &:first-child {
              display: flex;
              flex: 1;
              top: 100px;
              font-size: 12px;
              font-family: $font-nexa-regular;
              line-height: 15px;
            }

            &:nth-child(2) {
              position: absolute;
              top: 120px;
              font-family: $font-nexa-regular;
              font-weight: 400;
              font-size: 15px;
              line-height: 16px;
              letter-spacing: 1.2px;
            }

            &:nth-child(3) {
              top: 165px;
              font-family: $font-nexa-regular;
              font-weight: 200;
              font-size: 12px;
              font-size: 13.2139px;
              line-height: 16px;
            }

            &:last-child {
              top: 180px;
              font-family: $font-nexa-regular;
              font-weight: 400;
              font-size: 18px;
              line-height: 29px;
              letter-spacing: -0.94385px;
            }
          }
        }

        img {
        }
      }

      .recents {
        h6 {
          font-size: 16px;
          line-height: 26px;
          letter-spacing: -0.2px;
          color: $black3;
          font-family: $font-nexa-regular;
        }

        .recent-list {
          display: flex;
          flex-direction: column;
          max-width: 250px;

          .list-item {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
              display: flex;
              flex-direction: column;

              h6 {
                color: $data-text;
                font-family: $font-nexa-regular;
                margin-bottom: 0;
                font-size: 12px;
              }
            }

            p {
              color: $data-text;
              font-family: $font-nexa-regular;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    hr {
      width: 2px;
      height: 85%;
    }

    .right-side-section {
      display: flex;
      padding: 10px;
      flex: 4;
      flex-basis: 40%;
      height: 100%;
      flex-wrap: wrap;

      h6 {
        font-size: 26px;
        line-height: 26px;
        letter-spacing: -0.2px;
        color: $black3;
      }

      p {
      }
    }
  }
}

.big-card {
  // width: 300;
  // height: auto;
  position: relative;

  display: flex;

  .details {
    position: absolute;
    left: 25px;

    div {
      color: $white;
      position: absolute;
      width: max-content;

      &:first-child {
        display: flex;
        flex: 1;
        top: 100px;
        font-size: 12px;
        font-family: $font-nexa-regular;
        line-height: 15px;
      }

      &:nth-child(2) {
        position: absolute;
        top: 120px;
        font-family: $font-nexa-regular;
        font-weight: 400;
        font-size: 15px;
        line-height: 16px;
        letter-spacing: 1.2px;
      }

      &:nth-child(3) {
        top: 165px;
        font-family: $font-nexa-regular;
        font-weight: 200;
        font-size: 12px;
        font-size: 13.2139px;
        line-height: 16px;
      }

      &:last-child {
        top: 180px;
        font-family: $font-nexa-regular;
        font-weight: 400;
        font-size: 18px;
        line-height: 29px;
        letter-spacing: -0.94385px;
      }
    }
  }
}

.topContainer {
  display: flex;
  gap: 50px;
  // flex-wrap: wrap
}

.rightContainer {
  border-left: 1px solid #d4d4d4;
  flex: 1 1;
  padding: 0px 50px;
  // min-width: fit-content;
}

.textGroupContainer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 80px;
  row-gap: 20px;

  margin-bottom: 20px;
}

// .textGroupContainer {
//   // display: flex;
//   // flex-wrap: wrap;
//   // column-gap: 80px;
//   // row-gap: 20px;

//   // margin-bottom: 20px;
// }

.chartHeading {
  font-size: 25px;
}

.chartWrapper {
  width: 99%;
  border: 1px solid #d4d4d4;
  padding: 20px 20px;
  border-radius: 22px;
}

.leftContainer {
  flex: 0.7;
  border: 1px solid #d4d4d4;
  padding: 30px 20px;
  border-radius: 22px;
}

.cardResponsive {
  margin-top: 30px;
  margin-bottom: 10px;
  width: 350px;
}
