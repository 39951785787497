:root {
  --primary-color: #4A58D1;
  --secondary-color: #000080;
}

$primary: var(--primary-color);
$secondary: var(--secondary-color);
$white: #ffffff;
$black: #000000;
$black_2: #000D1E;
$green-light: #64B612;
$black3: #2D4164;
$light-black: #464255;
$dark-gray: #AEAEAE;
$darken-gray: #B9BBBD;
$gray: #F3F4F7;
$light-gray: #FDFDFD;
$error: #EB001B;
$redish: #ED5757;
$green: #216D32;
$background: #FBFBFC;
$gray-text: #B4C6E8;
$light-purple: #E4EDFD;
$lightest-purple: #F1F6FE;
$text-gray: #A3A3A3;
$picker: #F9F8F9;
$green-dark: #52B167;
$input-back: #F3F4F6;
$data-head: #4D5B63;
$data-text: #363C40;

$text-color: #787d98;
$blue-dark: #779EE1;
$wild-blue: #a1a8cb;
$alice-blue: #f6f9fe;
$stroke-color: #D4D4D4;
$orange: #ff7d36;
$purple: #a681e3;
$linkColor: #1890ff;
$starColor: #f8cf4f;
$borderBlack: #2e2e2e;
$button-filled: #e7eff5;
$green-shade: #E9F2EC;
$purple-shade: #E3E6FF;
$gray-shade: #EEE;
$border-light: #BBC5D8;
$light-back: #F4F6F9;

//font families
$font-bold : 'Manrope-Bold';
$font-extra-bold : 'Manrope-ExtraBold';
$font-extra-light : 'Manrope-ExtraLight';
$font-light : 'Manrope-Light';
$font-medium : 'Manrope-Medium';
$font-regular : 'Manrope-Regular';
$font-semiBold : 'Manrope-SemiBold';
$font-nexa-regular : 'Nexa-Regular';
$font-nexa-bold : 'Nexa-Bold';
$font-space-bold : 'SpaceGrotesk-Bold';
$font-space-light : 'SpaceGrotesk-Light';
$font-space-medium : 'SpaceGrotesk-Medium';
$font-space-regular : 'SpaceGrotesk-Regular';
$font-space-semiBold : 'SpaceGrotesk-SemiBold';