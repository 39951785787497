.c-field-container {
  margin: 10px 15px 10px 0;

  &.company-drop {
    min-width: 240px;
  }

  .c-field {
    .c-field-left-icon {
      width: 16px;
      margin-left: 16px;
      color: #CFCFCF;
      transition: 0.3s ease all;
    }

    .input-title {
      margin: 0 10px 5px 1px;
      color: $text-color;
      font-size: 10px;
      font-family: $font-regular;
    }

    .clear-text {
      flex: 1;
      border: none;
      background-color: transparent;
      height: 100%;
      min-height: 20px;
      font-size: 14px;
      font-family: $font-regular;
      color: $black;
      width: 100%;
      transition: 0.3s ease all;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 16px;
    }

    &.with-prefix {
      .ant-input-affix-wrapper {
        .ant-input-prefix {
          border: 1px solid $dark-gray;
          border-right: 0;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          min-height: 32px;
          font-size: 14px;
          font-family: $font-regular;
          color: $black;
          margin: 0;
          padding-left: 10px;
          background-color: $input-back;
          transition: 0.3s ease all;
        }

        &.ant-input-affix-wrapper-focused {
          .ant-input-prefix {
            border-color: $primary;
            box-shadow: none;
            border-width: 1.5px;
          }
        }

        input {
          border-left: 0;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }

    input {
      flex: 1;
      background-color: $input-back;
      min-height: 32px;
      font-size: 10px;
      font-family: $font-regular;
      color: $black;
      height: 32px;
      width: 100%;
      border: 1px solid $dark-gray;
      box-sizing: border-box;
      border-radius: 8px;
      transition: 0.3s ease all;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 18px;

      &:focus {
        border-color: $primary;
        box-shadow: none;
        border-width: 1.5px;
      }

      &:disabled {
        border-color: $dark-gray;
        background-color: $gray;
        box-shadow: none;
        color: $text-color;
      }

      &::placeholder {
        font-size: 10px;
        font-family: $font-regular;
        color: $dark-gray;
      }
    }

    textarea {
      flex: 1;
      border: none;
      background-color: $input-back;
      height: 100%;
      min-height: 32px;
      font-size: 10px;
      font-family: $font-regular;
      color: $black;

      width: 100%;
      border: 1px solid $dark-gray;
      box-sizing: border-box;
      border-radius: 8px;
      transition: 0.3s ease all;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 18px;

      &:focus {
        border-color: $primary;
        box-shadow: none;
        border-width: 1.5px;
      }

      &:disabled {
        border-color: $dark-gray;
        background-color: $gray;
        box-shadow: none;
        color: $text-color;
      }

      &::placeholder {
        font-size: 10px;
        font-family: $font-regular;
        color: $dark-gray;
      }
    }

    .loading {
      position: relative !important;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      z-index: 1;
    }

    .ant-picker {
      border: none !important;
      background-color: $input-back;
      padding: 0;
      height: 32px !important;
      min-height: 32px;
      box-shadow: none;

      .ant-picker-suffix {
        position: absolute;
        right: 8%;
      }

      .ant-picker-clear {
        position: absolute;
        right: 8%;
      }
    }

    .ant-input-affix-wrapper {
      border: none;
      background-color: transparent;
      padding: 0;
      box-shadow: none;

      input {
        padding-right: 50px;
      }

      .ant-input-suffix {
        position: absolute;
        right: 0;
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: 20px;
      }
    }

    .prefix {
      position: relative;
      height: 100%;

      .ant-select {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        background-color: transparent !important;

        .ant-select-selector {
          border: none !important;
          height: 100%;
          padding: 0 0 0 15px !important;
          background-color: transparent !important;

          .ant-select-selection-search {
            input {
              width: 80%;
              font-size: 11px;
              background-color: transparent !important;
            }
          }
        }
      }

      input {
        padding-left: 75px;
      }
    }

    &.has-error {
      &.with-prefix {
        .ant-input-affix-wrapper {
          .ant-input-prefix {
            border-color: $error !important;
            background-color: rgba(255, 0, 0, 0.03) !important;
          }
        }
      }

      input {
        border-color: $error !important;
        background-color: rgba(255, 0, 0, 0.03) !important;
      }

      textarea {
        border-color: $error !important;
        background-color: rgba(255, 0, 0, 0.03) !important;
      }

      .ant-select {
        .ant-select-selector {
          border-color: $error !important;
          background-color: rgba(255, 0, 0, 0.03) !important;

          input {
            background-color: transparent !important;
          }
        }

        &.multi {
          .ant-select-selector {
            border-color: $error !important;
            background-color: rgba(255, 0, 0, 0.03) !important;
          }
        }
      }
    }
  }

  &:focus-within {
    border-color: $primary;

    .c-field-left-icon {
      color: $primary;
    }
  }

  // select,
  .ant-select {
    width: 100%;
    background: none;
    border: none;
    box-sizing: border-box;
    transition: 0.3s ease all;

    &.ant-select-focused {
      .ant-select-selector {
        border-color: $primary !important;
      }
    }

    &.prefix {
      flex: 0.2 !important;
      width: 75px !important;
      color: $black;
    }

    &.multi {
      .ant-select-selector {
        padding: 0px 9px !important;

        .ant-select-selection-overflow {
          .ant-select-selection-overflow-item {
            .ant-select-selection-search {
              left: 0px;
            }
          }
        }

        .ant-select-selection-item {
          background: #e8e4e4;
        }

        // .ant-select-selection-placeholder {
        //   left: 0px !important;
        // }
      }
    }
  }

  .ant-select-selector {
    background-color: $input-back !important;
    // border: none !important;
    border: 1px solid $dark-gray !important;
    box-sizing: border-box;
    border-radius: 8px !important;
    display: flex !important;
    flex: 1 !important;
    min-height: 32px !important;
    box-shadow: none !important;
    padding: 9px 20px !important;
    font-size: 10px;
    overflow: hidden;
    align-items: center;

    > .ant-select-selection-search {
      left: 20px;
    }

    .ant-select-selection-wrap {
      .ant-select-selection-item {
        display: flex;
        font-size: 10px;
        align-self: center;
        line-height: 32px;
      }
    }

    &.hide {
      -webkit-appearance: none;
    }

    &.placeholder {
      color: $text-color;
      font-size: 10px;
    }
  }

  &.rangePicker {
    flex: 1;
    // border: 1px solid $dark-gray;
    padding: 0;
    box-shadow: none;
    border-radius: 8px;

    .ant-picker-suffix {
      // right: 0% !important;
      position: relative !important;
      padding: 0 15px;
      // position: absolute;
      right: 0 !important;
    }

    .ant-picker-clear {
      padding: 0 15px;
      // position: relative !important;
      position: absolute;
      right: 0 !important;
      background-color: $input-back !important;
    }

    .ant-picker-range {
      background-color: $input-back !important;
      border-radius: 8px;

      &:nth-child(3) {
        padding-right: 80px;
      }

      .ant-picker-input {
        border: none;
        background-color: transparent !important;

        input {
          border: none;
          height: 32px !important;
          min-height: 32px;
          padding: 8px 0 8px 18px;

          &:focus {
            border-color: $primary;
            box-shadow: none;
            border-width: 1.5px;
          }

          &:disabled {
            border-color: $dark-gray;
            background-color: $gray;
            box-shadow: none;
            color: $text-color;
          }

          &::placeholder {
            font-size: 10px;
            font-family: $font-regular;
            color: $dark-gray;
          }
        }
      }
    }
  }
}

.ant-select-item-option-content {
  font-family: $font-regular;
  font-size: 10px;
}

.c-checkbox {
  input {
    color: $white;
    margin-right: 10px;
    width: 15px;
    height: 15px;
    accent-color: $primary;
  }

  label {
    font-size: 18px;
    line-height: 27px;
  }
}

.error {
  font-size: 10px;
  font-family: $font-regular;
  color: $error;
  margin-top: 6px;
}

.linked-establishment-select {
  display: flex;
  line-height: 16px;
  flex-direction: column;
}