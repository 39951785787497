.button-container {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  align-items: center;
  margin: 10px 15px 10px 0;
  // width: 100%;

  &.m-top {
    margin-top: 30px;
  }

  &.m-bottom {
    margin-bottom: 30px;
  }

  .input-title {
    margin: 0 10px 5px 10px;
    color: $text-color;
    font-size: 10px;
    font-family: $font-regular;
    align-self: baseline;
  }

  .c-button {
    background-color: $primary;
    border: 1px solid $primary;
    // box-shadow: 0 15px 35px -2px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    width: 100%;
    height: 32px;
    cursor: pointer;
    transition: 0.3s ease all;
    min-width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-family: $font-regular;
      font-size: 12px;
      line-height: 22px;
      text-align: center;
      color: $white;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
      .c-button-left-icon {
        margin-right: 10px;
        //color: $white;
        transition: 0.3s ease all;
        width: 16px;
        height: 16px;
      }
    }

    &:hover:enabled {
      background-color: $white;

      span {
        color: $primary;
      }
    }

    &:hover:disabled {
      background-color: $primary;
    }

    &.large {
      min-width: 200px;
    }

    &.small {
      min-width: 80px;
      span {
        .c-button-left-icon {
          width: 12px;
          height: 12px;
          margin-right: 5px;
        }
      }
    }

    &:active {
      box-shadow: none;
      border-color: $white;
      transition: 0.3s ease all;
    }

    &.round {
      border-radius: 30px;
    }

    &.primary-outline {
      background-color: $white;
      border-color: $primary;

      &:hover:disabled {
        background-color: $white;
        border-color: $primary;
      }

      &:hover:enabled {
        background-color: $primary;

        span {
          color: $white;
        }
      }

      span {
        color: $primary;
      }
    }

    &.primary-outline-filled {
      background-color: $button-filled;
      border-color: $primary;

      &:hover:disabled {
        background-color: $button-filled;
        border-color: $primary;
      }

      &:hover:enabled {
        background-color: $primary;

        span {
          color: $white;
        }
      }

      span {
        color: $primary;
      }
    }

    &.invisible-button {
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;

      &:hover:disabled {
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
      }

      &:hover:enabled {
        span {
          color: $primary;
        }
      }

      span {
        color: $black;
      }
    }

    &.primary {
      background-color: $primary;

      &:hover:enabled {
        background-color: $button-filled;

        span {
          color: $primary;
        }
      }

      span {
        color: $white;
      }
    }

    &.green-dark-back {
      background-color: $green-dark;
      border: none;

      &:hover:disabled {
        background-color: $green-dark;
        border: none;
      }

      &:hover:disabled {
        background-color: $green-dark;
        border-color: transparent;
      }

      &:hover:enabled {
        border: 1px solid $green-dark;
        background-color: $white;

        span {
          color: $green-dark;
        }
      }
    }

    &.green-outline {
      background-color: transparent;
      color: $white;
      border: 1px solid $green-dark;

      &:hover:disabled {
        background-color: transparent;
        color: $white;
        border: 1px solid $green-dark;
      }

      &:hover:enabled {
        background-color: $green-dark;

        span {
          color: $white;
        }
      }

      span {
        color: $green-dark;
      }
    }

    &.red-outline {
      border-color: $redish;
      background-color: transparent;

      &:hover:disabled {
        border-color: $redish;
        background-color: transparent;
      }

      &:hover:enabled {
        background-color: $redish;

        span {
          color: $white;
        }
      }

      span {
        color: $redish;
      }
    }
    &.red-dark {
      border-color: $redish !important;
      background: $redish !important;

      &:hover:disabled {
        border-color: $redish !important;
        background-color: $redish !important;
      }

      &:hover:enabled {
        background-color: $white !important;
        border-color: $redish !important;
        span {
          color: $redish !important;
          // color: $white;
        }
      }

      span {
        color: $white !important;
      }
    }
    &.link-text {
      text-decoration: underline;
    }

    &.secondary {
      background-color: $secondary;

      &:hover:disabled {
        background-color: $secondary;
      }

      &:hover:enabled {
        border: 1px solid $secondary;
        background-color: $white;

        span {
          color: $secondary;
        }
      }

      span {
        color: $white;
      }
    }

    &.secondary-outline {
      background-color: $white;
      border-color: $secondary;

      &:hover:disabled {
        background-color: $white;
        border-color: $secondary;
      }

      &:hover:enabled {
        background-color: $secondary;

        span {
          color: $white;
        }
      }

      span {
        color: $secondary;
      }
    }

    &:disabled,
    &[disabled] {
      opacity: 0.8;
      box-shadow: none;
      cursor: not-allowed;
    }
  }

  &.gradient {
    .c-button {
      background: linear-gradient(127.39deg, #f3f3f3 25.24%, #f0f5fe 71.66%);
      border: 1px solid #dadee4;
      box-shadow: 0px 13px 16px rgba(63, 80, 111, 0.11);
      border-radius: 8px;
      width: 100%;
      height: 64px;
      max-height: 64px;
      cursor: pointer;
      transition: 0.3s ease all;
      min-width: 120px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        color: $black3;
        font-family: $font-bold;
        white-space: break-spaces;
        margin-right: 15px;
        align-self: center;

        &:hover {
          color: $white;
        }

        svg {
          color: $primary;
        }
      }
    }

    .c-button-left-icon {
      width: 22px;
      height: 22px;
      color: $primary;

      &:first-child {
        width: 24px;
        height: 24px;
      }
    }
  }
  &.gradient:hover {
    .c-button {
      background: $primary;
      span {
        color: $white;

        svg {
          color: $white;
          fill: $white;
        }
      }
    }
    .c-button-left-icon {
      color: $white !important;
    }
  }
}

.error {
  font-size: 12px;
  font-family: $font-regular;
  color: $error;
  margin-top: 6px;
}

.ant-btn[disabled],
.ant-btn[disabled]:active,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:hover {
  //background-color: transparent !important;
}
