.auth-container {
  display: flex;
  flex-direction: row;
  flex: 1;

  .auth-container-left {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    min-width: 450px;
    width: auto;
    background-color: $white;
    align-content: space-around;
    border-radius: 0 25px 25px 0;
    margin-right: -100px;
    z-index: 1;

    .auth-container-left-header {
      padding: 20px 40px;

      .auth-container-left-header-logo {
        width: 150px;
        height: 50px;
      }
    }

    .auth-container-left-body {
      padding: 0 40px;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      overflow-y: auto;
      @include scroll-y;

      .body {
        display: flex;
        flex-direction: column;
        // flex: 1;
      }

      .form-title {
        font-family: $font-regular;
        color: $black;
        margin-bottom: 0;
        margin-left: 10px;
        text-align: left;
        font-size: 22px;
        margin-bottom: 20px;
      }
      .auth-message {
        font-size: 13px;
        text-align: justify;
        color: red;
        margin: 15px 0;
      }

      .sub-title{
        font-size: 12px;
        text-align: justify;
      }
    }

    .auth-container-left-footer {
      padding: 20px 35px;

      .auth-container-left-footer-links {
        display: flex;
        flex-direction: row;
        align-items: center;

        a {
          font-family: $font-regular;
          font-size: 10px;
          line-height: 22px;
          color: $black;
          opacity: 0.34;
          text-transform: capitalize !important;
          display: flex;
          flex-direction: row;
          align-items: center;

          p{
            margin-bottom: 0;
          }

          &::after {
            content: "";
            display: flex;
            margin: 0 15px;
            width: 1px;
            height: 20px;
            background-color: $black;
            opacity: 0.34;
          }

          &:last-child {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }

  .auth-container-right {
    flex: 1;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    background: linear-gradient(
        269.5deg,
        rgba(0, 0, 0, 0.5) -44.87%,
        rgba(0, 0, 0, 0) 105.99%
      ),
      url("../../assets/images/login-bg.jpg") no-repeat;
    background-size: cover;

    h1 {
      font-family: $font-regular;
      font-size: 50px;
      line-height: 64px;
      text-align: right;
      letter-spacing: -3px;
      color: #ffffff;
      width: 90%;
      margin: 40px 100px;
    }
  }
}

p.s-up {
  text-align: center !important;
  margin-top: 10px 20px !important;
  font-size: 12px;
}

// otp field

.otp-container {
  display: flex;
  justify-content: space-evenly;

  .otp-field {

    &.lg-font {
      input {
        font-size: 14px !important;
      }
    }

    input {
      width: 100% !important;
      height: 40px !important;
      border-radius: 10px !important;
      border-color: #4a58d1 !important;
      margin: 5px;
      border: 1px solid;
      font-size: 12px !important;
      padding: 0 !important;
    }

    &:first-child {
      input {
        margin-left: 0;
      }
    }
    &:last-child {
      input {
        margin-right: 0;
      } 
    }
  }
}

.side-menu-body {
  overflow: auto !important;
}

// password field icon
// .c-field.pass-field img.c-field-left-icon {
//   cursor: pointer;
//   width: 20px;
// }

.forgot-pass {
  margin-right: 20px;
  font-size: 12px;
  span  {
    color: $linkColor;
  }
  p {
    color: $linkColor;
  }
}

// terms & policies start
.terms {
  width: 100%;
  background: #fff;
  .title {
    background: linear-gradient(
      90deg,
      rgba(3, 3, 135, 1) 27%,
      rgba(66, 66, 168, 1) 63%
    );
    color: #fff;
    padding: 60px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    svg {
      margin: 20px;
      cursor: pointer;
    }
    h1.title-head {
      color: #fff;
      border-bottom: 1px solid #fff !important;
    }
  }
  .terms-text {
    padding: 50px;
    background: #fff;
  }
}
// terms & policies end